/**
 * @file Section
 */

import React from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

class Section extends React.Component {
  animate = () => {
    gsap.set(this.contentRef, { opacity: 0 })

    ScrollTrigger.create({
      trigger: this.contentRef,
      once: true,
      onEnter: () => {
        gsap.fromTo(this.contentRef, {x: 0, y: 100, opacity: 0}, {
          duration: 2.25,
          x: 0,
          y: 0,
          opacity: 1,
          ease: 'expo',
          overwrite: 'auto'
        });
      }
    })
  }

  componentDidMount() {
    this.animate()
  }

  render() {
    return (
      <section id={this.props.name} className={`section ${this.props.name}`}>
        <div className={`section__line ${this.props.offset && 'section__line--offset'}`} />
        <div ref={ref => this.contentRef = ref}>
          {this.props.children}
        </div>
      </section>
    )
  }
}

export default Section
