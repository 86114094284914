/**
 * @file Blog
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Section from '../section'
import { GatsbyImage } from 'gatsby-plugin-image'

class Blog extends React.Component {
  render() {
    return (
      <Section name="blog">
        <div className="section__content">
          <div className="text-center">
            <h2 className="section__heading section__heading--underline">
              Blog
            </h2>
          </div>
          <div className="relative before:absolute before:bg-white/90 before:inset-0 before:z-50">
            <div className="z-50 absolute inset-0 flex items-center justify-middle">
              <h3 className="text-cinder text-2xl uppercase font-secondary font-extrabold m-auto">Coming soon</h3>
            </div>
            <div className="grid md:grid-cols-12 gap-10 lg:gap-0 justify-center">
              <StaticQuery
                query={graphql`
                  query BlogQuery {
                    dataYaml {
                      blog {
                        name
                        link
                        image {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                `}
                render={({ dataYaml }) => (
                  dataYaml.blog.map((experiment, index) => (
                    <div className="md:col-span-6 xl:col-span-3" key={`experiment-${index}`}>
                      <div className="blog-card">
                        <GatsbyImage className="blog-card__image" image={experiment.image.childImageSharp.gatsbyImageData} alt="alt text" />
                      </div>
                    </div>
                  ))
                )}
              />
            </div>
          </div>
        </div>
      </Section>
    )
  }
}

export default Blog
