/**
 * @file FerrisWheel
 */

import React from 'react'
import { gsap } from 'gsap'

class FerrisWheel extends React.Component {
  state = {
    cables: [],
    spokes: [],
    capsules: []
  }

  nodes = {
    cables: {
      total: 50,
      rotation: 360 / 50,
      refs: []
    },
    spokes: {
      total: 50,
      rotation: 360 / 50,
      refs: []
    },
    capsules: {
      total: 50 / 2,
      rotation: 360 / 25,
      refs: []
    }
  }

  setRef = (ref) => {
    const type = ref.getAttribute('data-node-type');
    this.nodes[type].refs.push(ref)
  }

  generateNodes = (type) => {
    let nodes = []

    for (let i = 0; i < this.nodes[type].total; i++) {
      nodes.push({
        key: type+i,
        ref: type+i,
      })
    }

    this.setState({ [type]: nodes })
  }

  positionNodes = (type) => {
    for (let i = 0; i < this.nodes[type].total; i++) {
      gsap.set(this.nodes[type].refs[i], {
        x: 0,
        y: 0,
        rotation: ((this.nodes[type].rotation * i ) - 90)
      })
    }
  }

  animate = () => {
    gsap.to(this.rimOutsideRef, 1000, {
      rotation: '360',
      ease: 'linear',
      repeat: -1
    })

    gsap.fromTo(this.ferrisWheelRef, {
        y: 25
      }, {
        y: 0,
        opacity: .5,
        duration: 1.2,
    })

    gsap.to(this.ferrisWheelRef, {
      yPercent: 20,
      ease: 'none',
      scrollTrigger: {
        trigger: this.ferrisWheelRef,
        scrub: 1
      },
    })
  }

  componentDidMount() {
    Object.keys(this.nodes).forEach(node => (
      this.generateNodes(node)
    ))

    this.animate()
  }

  componentDidUpdate() {
    Object.keys(this.nodes).forEach(node => (
      this.positionNodes(node)
    ))

    return true
  }

  render() {
    return (
      <div className="ferris-wheel" ref={ref => this.ferrisWheelRef = ref}>
        <div className="ferris-wheel__leg ferris-wheel__leg--left"></div>
          <div className="ferris-wheel__rim ferris-wheel__rim--outside" ref={ref => this.rimOutsideRef = ref}>
            {this.state.capsules.map(capsule => (
              <div data-node-type="capsules" ref={this.setRef} key={`capsule-${capsule.key}`} className="ferris-wheel__capsule">
                <div className="ferris-wheel__capsule-pod"></div>
              </div>
            ))}
            {this.state.spokes.map(spoke => (
              <div data-node-type="spokes" ref={this.setRef} key={`spoke-${spoke.key}`} className="ferris-wheel__spoke"></div>
            ))}
            <div className="ferris-wheel__rim ferris-wheel__rim--inside">
              {this.state.cables.map(cable => (
                <div data-node-type="cables" ref={this.setRef} key={`cables-${cable.key}`} className="ferris-wheel__cable"></div>
              ))}
            </div>
            <div className="ferris-wheel__hub"></div>
          </div>
        <div className="ferris-wheel__leg ferris-wheel__leg--right"></div>
      </div>
    )
  }
}

export default FerrisWheel
