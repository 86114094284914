/**
 * @file About
 */

import React from 'react'
import { gsap } from 'gsap'
import Section from '../section'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

class About extends React.Component {
  render() {
    return(
      <Section name="about" offset={true}>
        <div className="section__content">
          <div className="grid xl:grid-cols-12 justify-center">
            <div className="xl:col-start-2 xl:col-span-10">
              <h2 className="section__heading">
                Hello
              </h2>
              <div className="grid md:grid-cols-12">
                <div className="md:col-start-2 md:col-span-10 lg:col-start-4 lg:col-span-6">
                  <p>
                    Hello, I'm Mike, a frontend developer. I bring ideas to life through code and turn complex problems into elegant, user-friendly interfaces.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    )
  }
}

export default About
