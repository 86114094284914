/**
 * @file Experiments
 */

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Section from '../section'
import { GatsbyImage } from 'gatsby-plugin-image'
gsap.registerPlugin(ScrollTrigger)

class Experiments extends React.Component {
  constructor(props) {
    super(props)
    this.contentRef = React.createRef()
    this.experimentsCardRef1 = React.createRef()
    this.experimentsCardRef2 = React.createRef()
    this.experimentsCardRef3 = React.createRef()
    this.experimentsCardRef4 = React.createRef()
  }

  componentDidMount() {
    ScrollTrigger.create({
      trigger: this.contentRef.current,
      once: true,
      onEnter: () => {
        gsap.fromTo('.experiments-card',
          {
            opacity: 0,
          },
          {
            duration: 1.7,
            opacity: 1,
            stagger: 0.2,
            overwrite: 'auto'
          }
      )}
    })
  }

  render() {
    return (
     <Section name="experiments">
      <div className="experiments__content" ref={this.contentRef}>
        <div className="text-center">
          <h2 className="section__heading section__heading--underline">Experiments</h2>
        </div>
        <div className="relative before:absolute before:bg-white/90 before:inset-0 before:z-50">
          <div className="z-50 absolute inset-0 flex items-center justify-middle">
            <h3 className="text-cinder text-2xl uppercase font-secondary font-extrabold m-auto">Coming soon</h3>
          </div>
          <div className="grid md:grid-cols-12"> 
              <StaticQuery
                query={graphql`
                  query ExperimentsQuery {
                    dataYaml {
                      experiments {
                        name
                        link
                        image {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                `}
                render={({ dataYaml }) => (
                  dataYaml.experiments.map((experiments, index) => (
                    <div className="md:col-span-6 xl:col-span-3" key={`experiments-${index}`}>
                      <div className="experiment-card">
                        <GatsbyImage className="experiment-card__image" image={experiments.image.childImageSharp.gatsbyImageData} alt="alt text" />
                        <a className="experiment-card__link" href={experiments.link} target="_blank" rel="noopener noreferrer">
                          <span className="sr-only">
                            View {experiments.name}
                          </span>
                        </a>
                        <div className="experiment-card__info">
                          {experiments.name}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              />
          </div>
        </div>
      </div>
     </Section>
    )
  }
}

export default Experiments
