/**
 * @file index.js
 * @author Mike Joyce [hello@mikejoyce.io]
 */

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import About from '../components/About'
import Feature from '../components/Feature'
import Blog from '../components/Blog'
import Layout from '../components/Layout'
import Experiments from '../components/Experiments'

const IndexPage = ({ children }) => (
  <Layout>
    <Feature />
    {/* <About />
    <Experiments />
    <Blog /> */}
  </Layout>
)

export default IndexPage
