/**
 * @file Feature
 */

import React from 'react'
import FerrisWheel from '../FerrisWheel'
import { gsap } from 'gsap'

class Feature extends React.Component {

  animate = () => {
    gsap.set(this.backgroundRef, { opacity: 0 })

    gsap.fromTo(this.backgroundRef, {
        y: 20
      }, {
        y: 0,
        opacity: 1,
        duration: .2,
    })

    gsap.to(this.backgroundRef, {
      yPercent: 5,
      ease: 'none',
      scrollTrigger: {
        trigger: this.featureRef,
        scrub: 10
      },
    })
  }

  componentDidMount() {
    this.animate()
  }

  render() {
    return (
      <>
        <div id="feature" className="feature feature--index" ref={ref => this.featureRef = ref}>
          <div className="feature__content">
            <h1 className="feature__heading">
              <span>Mike</span>
              Frontend <span></span>
              Developer
            </h1>
            <FerrisWheel />
          </div>
          <button type="button" className="feature__skip">
            <span className="sr-only">
              Skip to Main Content
            </span>
          </button>
          <div className="feature__line" />
          <div className="feature__background" ref={ref => this.backgroundRef = ref}/>
        </div>
      </>
    )
  }
}

export default Feature
